import {
  roles,
  paymentOfCreditCard,
  paymentOfOthers,
  physicalProductOrderStatusTextTranslation,
  transactionTypes,
  positions,
  management,
  ServiceProductSessionType,
  ServiceProductGameType,
  creditRecordActions,
  restaurantProductRemarks,
  ProductActivityLogUpdateReason,
  POSpaymentMethod,
  TransferProblemConfirmedAction,
  POSTransactionType,
  ProductActivityLogUpdateReasonText,
} from '@/data/constants';
import moment from 'moment';

export const roleOptions = [
  {
    label: 'Admin',
    value: roles.admin,
  },
  {
    label: 'Customer',
    value: roles.client,
  },
  {
    label: 'Instructor',
    value: roles.master,
  },
  {
    label: 'Cashier',
    value: roles.cashier,
  },
];

export const paymentOfCreditCardOptions = [
  {
    label: 'AE',
    value: paymentOfCreditCard.ae,
  },
  {
    label: 'Visa',
    value: paymentOfCreditCard.visa,
  },
  {
    label: 'Master',
    value: paymentOfCreditCard.master,
  },
];

export const paymentOfOthersOptions = [
  {
    label: 'Cash',
    value: paymentOfOthers.cash,
  },
  {
    label: 'Octopus Card',
    value: paymentOfOthers.octopusCard,
  },
  {
    label: 'Union Pay',
    value: paymentOfOthers.unionPay,
  },
  {
    label: 'Payme',
    value: paymentOfOthers.payMe,
  },
  {
    label: 'Paypal',
    value: paymentOfOthers.payPal,
  },
  {
    label: 'Alipay HK',
    value: paymentOfOthers.alipayHK,
  },
  {
    label: 'Bank Transfer',
    value: paymentOfOthers.bankTransfer,
  },
  {
    label: 'Cheque',
    value: paymentOfOthers.cheque,
  },
];

export const colorOption = [
  // Color Should Different
  { value: `ffffff`, label: 'Oil', description: `Oil` },
  { value: `f79433`, label: 'DO', description: `Dark Orange` },
  { value: `b41343`, label: 'DR', description: `Dark Red` },
  { value: `a96e46`, label: 'B', description: `Brown` },
  { value: `9a4724`, label: 'CO', description: `COCO` },
  { value: `974031`, label: 'CH', description: `Chestnut` },
  { value: `452e1f`, label: 'DB', description: `Dark Brown` },
  { value: `613b57`, label: 'DP', description: `Indigo` },
  { value: `811141`, label: 'RB', description: `Rose Brown` },
  { value: `c7c8ca`, label: 'HT', description: `Hair Mask` },
  { value: `8b0029`, label: 'WR', description: `Wine Red` },
  { value: `e2c321`, label: 'GO', description: `Gold` },
];
export const doseOption = [
  '0.5',
  '1',
  '1.5',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  // Color Should Different
];
export const durationOption = [
  '5',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
  '60',
];

export const durationOption2 = [
  'Only 1 Round',
  '5',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
  '60',
];

export const scalpSituationOption = [
  'Normal',
  'Hair Loss',
  'Sensitive / Red',
  'Dandruff',
  'Acne',
  'Itchy',
  'Eczema',
  'G6PD',
  'Other',
];
export const hairThicknessOption = [
  '銀離子染髮',
  'Red permanent dye / chinese dye',
  'Black permanent dye / chinese dye',
  'Chemical semi permanent',
  'Chemical light color',
  'Perm',
  'Other',
];
export const hairVolumeOption = ['Thick', 'Normal', 'Thin'];
export const hairConditionOption = [
  'Oily',
  'Normal',
  'Dry',
  'Frizzy',
  'Hair Loss',
  'Highlighted',
  'Chemical Dye',
  'Permed',
];
export const expectedColorResultOption = [
  'Dark Orange',
  'Dark Red',
  'Coco',
  'Chestnut',
  'Light Brown',
  'Dark Brown',
  'Black as possible',
  'Other',
];
export const bookingStatusOption = [
  { value: '0$created', label: 'New Booking' },
  { value: '1$arrived', label: 'Arrived' },
  { value: '98$absent', label: 'Absent' },
  { value: '99$completed', label: 'Completed' },
  { value: '97$cancelled', label: 'Cancelled' },
];

export const districts = [
  'Central and Western',
  'Eastern',
  'Southern',
  'Wan Chai',
  'Kowloon City',
  'Kwun Tong',
  'Sham Shui Po',
  'Wong Tai Sin',
  'Yau Tsim Mong',
  'Islands',
  'Kwai Tsing',
  'North',
  'Sai Kung',
  'Sha Tin',
  'Tai Po',
  'Tsuen Wan',
  'Tuen Mun',
  'Yuen Long',
];

export const month = [
  { text: 'January', value: '01' },
  { text: 'February', value: '02' },
  { text: 'March', value: '03' },
  { text: 'April', value: '04' },
  { text: 'May', value: '05' },
  { text: 'June', value: '06' },
  { text: 'July', value: '07' },
  { text: 'August', value: '08' },
  { text: 'September', value: '09' },
  { text: 'October', value: '10' },
  { text: 'November', value: '11' },
  { text: 'December', value: '12' },
];

export const years = Array.from({ length: 20 }, (_, i) => String(2022 + i));

export const bookingSortOption = [
  { value: { '_timetableTime.start': -1 }, label: 'By Service Time' },
  { value: { created_at: -1 }, label: 'By Booking Time' },
];

export const time = Array.from({ length: 24 }, (
  // based on this project
  _,
  i,
) =>
  moment()
    .startOf('d')
    .add(i * 60, 'minutes')
    .format('HH:mm'),
);

export const leaveTypeOption = [
  { text: 'Sick Leave', value: 'SL' },
  { text: 'Annual Leave', value: 'AL' },
  { text: 'No Pay Leave', value: 'NPL' },
  { text: 'Compensatory Leave', value: 'CL' },
  { text: 'Absent', value: 'Absent' },
  { text: 'Other', value: 'Other' },
];

export const leaveApplicationStatusOption = [
  { value: '0$pending', label: 'Pending' },
  { value: '1$approved', label: 'Approved' },
  { value: '99$rejected', label: 'Rejected' },
];

export const physicalProductOrderStatusOption = [
  // Hidden(2024/7/18): Task - 130564260
  // { value: '0$pendingPayment', text: physicalProductOrderStatusTextTranslation.pendingPayment },
  // { value: '1$pendingDelivery', text: physicalProductOrderStatusTextTranslation.pendingDelivery },
  { value: '99$completed', text: physicalProductOrderStatusTextTranslation.completed },
  { value: '98$cancelled', text: physicalProductOrderStatusTextTranslation.cancelled },
  // { value: '97$expired', text: physicalProductOrderStatusTextTranslation.expired },
];

export const physicalProductOrderPaymentStatusOption = [
  { text: 'Pending', value: 'pending' },
  { text: 'Paid', value: 'paid' },
  { text: 'Fail', value: 'fail' },
];

export const transactionOption = [
  // {
  //   value: transactionTypes.TicketsPackage,
  //   text: 'Ticket Purchase',
  // },
  {
    value: transactionTypes.Booking,
    text: 'Service',
  },
  {
    value: transactionTypes.PhysicalProductOrder,
    text: 'Physical Product Order (Offline Only)',
  },
  // {
  //   value: transactionTypes.PayrollRecord,
  //   text: 'Payroll',
  // },
  {
    value: transactionTypes.computedOnlineOrder,
    text: 'Online Order',
  },
  // {
  //   value: transactionTypes.computedPurchaseInService,
  //   text: 'Purchase in Service',
  // },
  // {
  //   value: transactionTypes.restaurantService,
  //   text: 'Restaurant Service',
  // },
];

export const positionOption = Object.entries(positions).map(([key, value]) => {
  return {
    text: value,
    value: key,
  };
});

export const managementOptions = [
  {
    label: 'instructor',
    value: management.coach,
  },
  {
    label: 'Facility',
    value: management.facility,
  },
];

export const sessionTypeOptions = [
  {
    text: 'Entrance Only',
    value: ServiceProductSessionType.entranceOnly,
  },
  {
    text: 'Group Class',
    value: ServiceProductSessionType.groupClass,
  },
  {
    text: 'Private Training',
    value: ServiceProductSessionType.privateTraining,
  },
];
export const gameTypeOptions = [
  {
    text: 'Ski',
    value: ServiceProductGameType.ski,
  },
  {
    text: 'Snowboard',
    value: ServiceProductGameType.snowboard,
  },
  {
    text: 'Ground Tricks',
    value: ServiceProductGameType.groundTricks,
  },
  {
    text: 'Jibbing',
    value: ServiceProductGameType.jibbing,
  },
  {
    text: 'Flowboarding',
    value: ServiceProductGameType.flowboarding,
  },
];

export const CMSPostTypeOptions = [
  { text: 'home page', value: 'homePage' },
  { text: 'terms and conditions', value: 'termsAndConditions' },
  { text: 'privacy policy', value: 'privacyPolicy' },
];

export const CMSHomePageSectionOptions = [
  { text: 'header', value: 'header' },
  { text: 'About Us', value: 'aboutUs' },
  { text: 'introduction 1', value: 'introduction1' },
  { text: 'introduction 2', value: 'introduction2' },
];

export const creditRecordAction = [
  {
    label: 'Topup',
    value: creditRecordActions.topup,
  },
  {
    label: 'Usage',
    value: creditRecordActions.usage,
  },
  {
    label: 'Refund',
    value: creditRecordActions.refund,
  },
];

export const creditRecordStatus = [
  {
    label: 'Inactive',
    value: '0$inactive',
  },
  {
    label: 'Active',
    value: '1$active',
  },
];

export const bootsOptions = [
  { text: '38 (EU)', value: '38 (EU)' },
  { text: '39 (EU)', value: '39 (EU)' },
  { text: '40 (EU)', value: '40 (EU)' },
  { text: '41 (EU)', value: '41 (EU)' },
  { text: '42 (EU)', value: '42 (EU)' },
  { text: '43 (EU)', value: '43 (EU)' },
  { text: '44 (EU)', value: '44 (EU)' },
];

export const bindingsOptions = [
  { text: '8 (CM)', value: '8 (CM)' },
  { text: '9 (CM)', value: '9 (CM)' },
  { text: '10 (CM)', value: '10 (CM)' },
  { text: '11 (CM)', value: '11 (CM)' },
  { text: '12 (CM)', value: '12 (CM)' },
  { text: '13 (CM)', value: '13 (CM)' },
  { text: '14 (CM)', value: '14 (CM)' },
  { text: '15 (CM)', value: '15 (CM)' },
  { text: '16 (CM)', value: '16 (CM)' },
  { text: '17 (CM)', value: '17 (CM)' },
];

export const restaurantProductRemarksOptions = [
  { text: 'Oil', value: restaurantProductRemarks.oil },
  { text: 'Salt', value: restaurantProductRemarks.salt },
  { text: 'Sugar', value: restaurantProductRemarks.sugar },
  { text: 'Ice Level', value: restaurantProductRemarks.ice },
  { text: 'Rice', value: restaurantProductRemarks.rice },
];

export const productRemarksOptions = [
  // { text: 'Oil', value: restaurantProductRemarks.oil },
  // { text: 'Salt', value: restaurantProductRemarks.salt },
  // { text: 'Sugar', value: restaurantProductRemarks.sugar },
  // { text: 'Ice Level', value: restaurantProductRemarks.ice },
  // { text: 'Rice', value: restaurantProductRemarks.rice },
];

export const productActivityLogUpdateReasonOptions = [
  {
    text: ProductActivityLogUpdateReasonText.Purchase,
    value: ProductActivityLogUpdateReason.Purchase,
  },
  {
    text: ProductActivityLogUpdateReasonText.PreOrder,
    value: ProductActivityLogUpdateReason.PreOrder,
  },
  {
    text: ProductActivityLogUpdateReasonText.TransferIn,
    value: ProductActivityLogUpdateReason.TransferIn,
  },
  {
    text: ProductActivityLogUpdateReasonText.TransferOut,
    value: ProductActivityLogUpdateReason.TransferOut,
  },
  {
    text: ProductActivityLogUpdateReasonText.DeliveryLocal,
    value: ProductActivityLogUpdateReason.DeliveryLocal,
  },
  {
    text: ProductActivityLogUpdateReasonText.DeliveryMainLand,
    value: ProductActivityLogUpdateReason.DeliveryMainLand,
  },
  {
    text: ProductActivityLogUpdateReasonText.Display,
    value: ProductActivityLogUpdateReason.Display,
  },
  {
    text: ProductActivityLogUpdateReasonText.UnDisplay,
    value: ProductActivityLogUpdateReason.UnDisplay,
  },
  { text: ProductActivityLogUpdateReasonText.Hold, value: ProductActivityLogUpdateReason.Hold },
  { text: ProductActivityLogUpdateReasonText.Unhold, value: ProductActivityLogUpdateReason.Unhold },
  {
    text: ProductActivityLogUpdateReasonText.Adjustment,
    value: ProductActivityLogUpdateReason.Adjustment,
  },
  { text: ProductActivityLogUpdateReasonText.Loss, value: ProductActivityLogUpdateReason.Loss },
  {
    text: ProductActivityLogUpdateReasonText.Defected,
    value: ProductActivityLogUpdateReason.Defected,
  },
  { text: ProductActivityLogUpdateReasonText.Broken, value: ProductActivityLogUpdateReason.Broken },
  {
    text: ProductActivityLogUpdateReasonText.SkipStock,
    value: ProductActivityLogUpdateReason.SkipStock,
  },
  { text: ProductActivityLogUpdateReasonText.Void, value: ProductActivityLogUpdateReason.Void },
  {
    text: ProductActivityLogUpdateReasonText.VoidWithoutStockChange,
    value: ProductActivityLogUpdateReason.VoidWithoutStockChange,
  },
  {
    text: ProductActivityLogUpdateReasonText.Unknown,
    value: ProductActivityLogUpdateReason.Unknown,
  },
  { text: ProductActivityLogUpdateReasonText.Other, value: ProductActivityLogUpdateReason.Other },
];

export const POSpaymentMethodsOptions = [
  // { text: 'Fps', value: POSpaymentMethod.FPS },
  { text: 'Visa', value: POSpaymentMethod.visa },
  { text: 'Master', value: POSpaymentMethod.master },
  { text: 'AE', value: POSpaymentMethod.ae },
  { text: 'Union pay', value: POSpaymentMethod.unionpay },
  { text: 'Octopus', value: POSpaymentMethod.octopus },
  { text: 'Cash', value: POSpaymentMethod.cash },
  // NOTE: MATCHING PAYMENTS TO POS PAYMENTS OPTIONS
  // { text: 'Alipay', value: POSpaymentMethod.alipay },
  { text: 'Alipay HK', value: POSpaymentMethod.alipay_hk },
  { text: 'External Gateway', value: POSpaymentMethod.external_gateway },
  { text: 'Wechat Pay', value: POSpaymentMethod.wechat },
  { text: 'Wechat Pay HK', value: POSpaymentMethod.wechat_hk },
  { text: 'BOC', value: POSpaymentMethod.boc },
  { text: 'Tap&go', value: POSpaymentMethod.tap_and_go },
  { text: 'Union Pay c', value: POSpaymentMethod.unionpay_c },
  { text: 'Google Pay', value: POSpaymentMethod.googlepay },
  { text: 'Apple Pay', value: POSpaymentMethod.applepay },
  { text: 'Huawei Pay', value: POSpaymentMethod.huaweipay },
  { text: 'Samsung Pay', value: POSpaymentMethod.samsungpay },
  { text: 'Member Credit', value: POSpaymentMethod.member_credit },
];

export const TransferProblemConfirmedActionOptions = [
  {
    label: 'Added To Sender',
    value: TransferProblemConfirmedAction.AddedToSender,
  },
  {
    label: 'Added To Receiver',
    value: TransferProblemConfirmedAction.AddedToReceiver,
  },
  {
    label: 'Only Confirmed',
    value: TransferProblemConfirmedAction.OnlyConfirmed,
  },
];

export const POSTransactionTypesOptions = [
  { text: 'Income', value: POSTransactionType.income },
  { text: 'Expense', value: POSTransactionType.expense },
];

export const purchaseOrderStatusOption = [
  { value: 'pending', label: 'Pending' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'received', label: 'Received' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'completed', label: 'Completed' },
];

export const productActivityLogUpdateReasonTranslateOptions = [
  {
    text: ProductActivityLogUpdateReasonText.Purchase,
    value: ProductActivityLogUpdateReason.Purchase,
  },
  {
    text: ProductActivityLogUpdateReasonText.PreOrder,
    value: ProductActivityLogUpdateReason.PreOrder,
  },
  {
    text: ProductActivityLogUpdateReasonText.TransferIn,
    value: ProductActivityLogUpdateReason.TransferIn,
  },
  {
    text: ProductActivityLogUpdateReasonText.TransferOut,
    value: ProductActivityLogUpdateReason.TransferOut,
  },
  {
    text: ProductActivityLogUpdateReasonText.TransferCanceled,
    value: ProductActivityLogUpdateReason.TransferCanceled,
  },
  {
    text: ProductActivityLogUpdateReasonText.DeliveryLocal,
    value: ProductActivityLogUpdateReason.DeliveryLocal,
  },
  {
    text: ProductActivityLogUpdateReasonText.DeliveryMainLand,
    value: ProductActivityLogUpdateReason.DeliveryMainLand,
  },
  {
    text: ProductActivityLogUpdateReasonText.Display,
    value: ProductActivityLogUpdateReason.Display,
  },
  {
    text: ProductActivityLogUpdateReasonText.UnDisplay,
    value: ProductActivityLogUpdateReason.UnDisplay,
  },
  { text: ProductActivityLogUpdateReasonText.Hold, value: ProductActivityLogUpdateReason.Hold },
  { text: ProductActivityLogUpdateReasonText.Unhold, value: ProductActivityLogUpdateReason.Unhold },
  {
    text: ProductActivityLogUpdateReasonText.Adjustment,
    value: ProductActivityLogUpdateReason.Adjustment,
  },
  { text: ProductActivityLogUpdateReasonText.Loss, value: ProductActivityLogUpdateReason.Loss },
  {
    text: ProductActivityLogUpdateReasonText.Defected,
    value: ProductActivityLogUpdateReason.Defected,
  },
  { text: ProductActivityLogUpdateReasonText.Broken, value: ProductActivityLogUpdateReason.Broken },
  {
    text: ProductActivityLogUpdateReasonText.SkipStock,
    value: ProductActivityLogUpdateReason.SkipStock,
  },
  { text: ProductActivityLogUpdateReasonText.Void, value: ProductActivityLogUpdateReason.Void },
  {
    text: ProductActivityLogUpdateReasonText.VoidWithoutStockChange,
    value: ProductActivityLogUpdateReason.VoidWithoutStockChange,
  },
  {
    text: ProductActivityLogUpdateReasonText.Unknown,
    value: ProductActivityLogUpdateReason.Unknown,
  },
  { text: ProductActivityLogUpdateReasonText.Other, value: ProductActivityLogUpdateReason.Other },
  {
    text: ProductActivityLogUpdateReasonText.ProblemAdjustment,
    value: ProductActivityLogUpdateReason.ProblemAdjustment,
  },
  {
    text: ProductActivityLogUpdateReasonText.ZCWOReserved,
    value: ProductActivityLogUpdateReason.ZCWOReserved,
  },
  {
    text: ProductActivityLogUpdateReasonText.updateByCSV,
    value: ProductActivityLogUpdateReason.updateByCSV,
  },
  {
    text: ProductActivityLogUpdateReasonText.AdminBulkStockTransfer,
    value: ProductActivityLogUpdateReason.AdminBulkStockTransfer,
  },
];
